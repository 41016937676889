export const linksDic = {
  "1XU3bpkdsgJePawMWXB0": "https://dguidetours.com/OldCityJRS/",
  MnmUJZoT4znQur4YQjMu: "https://dguidetours.com/erezIsraeli/",
  "99gZnhiNu4sex1E0Ypd4": "https://dguidetours.com/firstStation/",
  DVl8TCMY8VhQdN9qr1Nc: "https://dguidetours.com/grafitiyulTLV/",
  QwvsqpPJMHwVQX6Qdvyr: "https://dguidetours.com/hamizvadaJRS/",
  rljiQyhYwm2pMEnZgDaE: "https://dguidetours.com/kingDavidJrs/",
  "2RsJUf7rYXZpZnkfOCqr": "https://dguidetours.com/levLevinski/",
  n0Kuwb71znhTCxu4nZQ4: "https://dguidetours.com/nadavJaffa/",
  d5oxDdgraYCiqEf3uYyJ: "https://dguidetours.com/OldCityOren/",
  lfngCB2AQGIoIdVkiIdu: "https://dguidetours.com/shukSale/",
  FXP91pj5gLMYP9K9SYe0: "https://dguidetours.com/TLVnight/",
  fZpUFiTLR1Zmmcr5kzKT: "https://dguidetours.com/TravelTrailer1/",
  zXWLehWWGeUZs1dvmwTE: "https://dguidetours.com/TravelTrailer2/",
  QzmZA14cPQC9bNVp1MyU: "https://dguidetours.com/JoeNachlaot/",
  "4JlfowL5ZqGt5tF3egH1": "https://dguidetours.com/einkeremNadav",
  "7Oo4lNT5mYbIGk5mty1L": "https://dguidetours.com/masadaAmi",
  f3nmoTZ0AzJDuwcJavJd: "https://dguidetours.com/sagolbarmon",
  vEkQmtiVgM96L0BPuB54: "https://dguidetours.com/hatikvaLev",
  XhYJ2O5vESHnjtqjrQTd: "https://dguidetours.com/WadiNisNas",
  CTQTBUBccHjhEPqtqjTb: "https://dguidetours.com/beTLVg",
  FuVaHdIgKjP2nYRvB9mo: "https://dguidetours.com/CaesareaNick",
  orR8LG1DR1LDaTZ9it7w: "https://dguidetours.com/chenShuk",
  nRc0poh1KwVwPOtKt4R6: "https://dguidetours.com/DavidJewishQuarter",
  aDdt2g6RFxeheYkqzMGj: "https://dguidetours.com/DavidOffWall",
  WO6l9qR5pPO25Y9oIXNO: "https://dguidetours.com/grafitiyulJRSmorning",
  GO3eomYx0O9bLQluxf8J: "https://dguidetours.com/ilanMoshava",
  COmEhNXyAKdNiB2pGanZ: "https://dguidetours.com/mazia",
  WNCL1qBE0bs5TObAGpnt: "https://dguidetours.com/museuMan",
  F7cCMatDzNcBGKYt9ZJU: "https://dguidetours.com/ShukWithTali",
  MCEwhQmMu9NZAQtv6jif: "https://dguidetours.com/SusieToursSarona",
  MQ9lMrABBtCvTZ1g1JyW: "https://dguidetours.com/zuzuYMSH",
  cb0qYZ2TwG73wavSrIGJ: "https://dguidetours.com/OldCitySli",
  ffv7KWJnSrt9u9zm1MYY: "https://dguidetours.com/grafitiyulTLVen",
  xVh6VucQIhm7GvZVtb41: "https://dguidetours.com/EinKPh",
  "8Vs1GNvyr56RH3jsBq1n": "https://dguidetours.com/8Vs1GNvyr56RH3jsBq1n",
  UdxGCzh3WBb4HvufyXI0: "https://dguidetours.com/adiAshkelon",
  b16wOFd4frb3mLmYBV4o: "https://dguidetours.com/b16wOFd4frb3mLmYBV4o",
  hoFEt5Ay3IcEbXp5qeHM: "https://dguidetours.com/hoFEt5Ay3IcEbXp5qeHM",
  bVbAatHRMdWU8uFgf01N: "https://dguidetours.com/hoFEt5Ay3IcEbXp5qeHM",
  "9Ykij5KibHyNZYL9gEwF": "https://dguidetours.com/9Ykij5KibHyNZYL9gEwF",
  tE0ILUT6fmgGEU6waCg3: "https://dguidetours.com/tE0ILUT6fmgGEU6waCg3",
  SaETQW5XnDVaylgGdXbe: "https://dguidetours.com/SaETQW5XnDVaylgGdXbe",
  LOsJqcGuYho5x64eXsgM: "https://dguidetours.com/LOsJqcGuYho5x64eXsgM",
};
