import React, { Fragment } from "react";
import { ThemeProvider } from "styled-components";
import { Modal } from "@redq/reuse-modal";
import Sticky from "react-stickynode";
import { DrawerProvider } from "common/contexts/DrawerContext";
import { saasClassicTheme } from "common/theme/saasClassic";
import { ResetCSS } from "common/assets/css/style";
import {
  GlobalStyle,
  ContentWrapper,
} from "containers/SaasClassic/saasClassic.style";

import BannerSection from "Containers/Banner";
import ServiceSection from "Containers/Service";
import Navbar from "Containers/Navbar";

/* import PricingSection from "containers/SaasClassic/Pricing";
import PartnerSection from "containers/SaasClassic/Partner";
import TrialSection from "containers/SaasClassic/Trial";
import FeatureSection from "containers/SaasClassic/Feature";
import UpdateScreen from "containers/SaasClassic/UpdateScreen";
import TestimonialSection from "containers/SaasClassic/Testimonial";
import Newsletter from "containers/SaasClassic/Newsletter";
import Footer from "containers/SaasClassic/Footer"; */
import "@redq/reuse-modal/es/index.css";
import Seo from "components/seo";
import { graphql, StaticQuery } from "gatsby";

import Footer from "Containers/Footer";
import { proceedPayment, paymePromise } from "../common/components/payment";

import ScrollItems from "../Containers/ScrollItems";

const SaasClassic = ({ location }) => {
  const [Tours, setTours] = React.useState();
  const [lang, setLang] = React.useState("he");
  const [source, setSource] = React.useState("Dguide");

  React.useEffect(() => {
    const params = new URLSearchParams(location.search);
    let source = params.get("source");

    if (source) {
      setSource(source);
    }
  });

  const section_1 = {
    title: { he: "", en: "" },
    slogan: { he: "המבוקשים ביותר", en: "Most Wanted" },
    tours: [
      "DVl8TCMY8VhQdN9qr1Nc",
      "QwvsqpPJMHwVQX6Qdvyr",
      "lfngCB2AQGIoIdVkiIdu",
      "n0Kuwb71znhTCxu4nZQ4",
    ],
  };
  const section_2 = {
    title: { he: "", en: "" },
    slogan: { he: "החדשים ביותר", en: "Brandnew" },
    tours: [
      "MnmUJZoT4znQur4YQjMu",
      "4JlfowL5ZqGt5tF3egH1",
      "FXP91pj5gLMYP9K9SYe0",
      "f3nmoTZ0AzJDuwcJavJd",
    ],
  };
  const section_3 = {
    title: { he: "", en: "" },
    slogan: { he: "קולינרי", en: "Culinary" },
    tours: [
      "vEkQmtiVgM96L0BPuB54",
      "2RsJUf7rYXZpZnkfOCqr",
      "99gZnhiNu4sex1E0Ypd4",
      "XhYJ2O5vESHnjtqjrQTd",
    ],
  };

  return (
    <StaticQuery
      query={graphql`
        query allToursPageQuery {
          allTour {
            nodes {
              accessible
              all_media {
                name
                order
                poster_url
                profile
                type
                url
                poster_name
              }
              audience
              description
              distance
              duration
              equipment
              hours_range {
                max
                min
              }
              id
              kosher
              location
              main_sentense
              price
              rating {
                total
                votes
              }
              title
              tour_guide {
                email
                full_name
                id
                profile_image
              }
              type
              hostedBy
              tour_image_obj {
                title
                url
              }
            }
          }
        }
      `}
      render={(data) => {
        setTours(data.allTour.nodes);

        if (!Tours) return null;
        let sec_1 = Tours.filter(function (tour) {
          //return section_1.tours.indexOf(tour.id) === -1;
          return section_1.tours.includes(tour.id);
        });
        let sec_2 = Tours.filter(function (tour) {
          //return section_1.tours.indexOf(tour.id) === -1;
          return section_2.tours.includes(tour.id);
        });
        let sec_3 = Tours.filter(function (tour) {
          //return section_1.tours.indexOf(tour.id) === -1;
          return section_3.tours.includes(tour.id);
        });
        console.log("SEC_1", sec_1, "SEC_2", sec_2, "SEC_3", sec_3);
        return (
          <ThemeProvider theme={saasClassicTheme}>
            <Fragment>
              <Seo
                title={`Dguide`}
                description={"Dguide all tours!"}
                lang={"he"}
                meta={[
                  "סיורים",
                  "סיורים בירושלים",
                  "טיול בירושלים",
                  "טיול",
                  "טיול למשפחה",
                  "מקומות לטייל בשבת",
                  "איפה אפשר לטייל",
                  "המלצות לטיולים",
                  "טיול יום",
                  "סיור יום",
                  "אטרקציות בירושלים",
                  "סיור עצמאי",
                  "מדריך טיולים",
                  "יום כיף",
                  "מה לעשות בירושלים",
                  "סיורים מודרכים בירושלים",
                ]}
                keywords={["סיור מודרך", "סיור עצמאי", `מה אפשר לעשות ב `]}
              />
              <Modal />
              <ResetCSS />
              <GlobalStyle />

              <ContentWrapper style={{ background: "#f9f9f9" }}>
                <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
                  <DrawerProvider>
                    <Navbar lang={lang} setLang={setLang} />
                  </DrawerProvider>
                </Sticky>
                <div id="111" key="123">
                  <Fragment>
                    <ScrollItems
                      tours={sec_1}
                      lang={lang}
                      title={section_1.title[lang]}
                      slogan={section_1.slogan[lang]}
                      carouselSelector="firstG"
                      source={source}
                    />
                  </Fragment>
                </div>
                <div id="222" key="456">
                  <Fragment>
                    <ScrollItems
                      tours={sec_2}
                      lang={lang}
                      title={section_2.title[lang]}
                      slogan={section_2.slogan[lang]}
                      carouselSelector="secG"
                      source={source}
                    />
                  </Fragment>
                </div>
                <Fragment>
                  <ScrollItems
                    tours={sec_3}
                    lang={lang}
                    title={section_3.title[lang]}
                    slogan={section_3.slogan[lang]}
                    carouselSelector="thirG"
                    source={source}
                  />
                </Fragment>

                <Footer />
              </ContentWrapper>
            </Fragment>
          </ThemeProvider>
        );
      }}
    />
  );
};
export default SaasClassic;
