import React, { Fragment } from "react";
import { Link } from "gatsby";
import Fade from "react-reveal/Fade";
import Text from "common/components/Text";
import Heading from "common/components/Heading";
import Button from "common/components/Button";
import Image from "common/components/Image";
import Container from "common/components/UI/Container";
import Rating from "common/components/Rating";
import GlideCarousel from "common/components/GlideCarousel";
import GlideSlide from "common/components/GlideCarousel/glideSlide";
import { SectionHeader } from "./scrollitems.style";
import SectionWrapper, { CarouselWrapper } from "./scrollitems.style";
import { linksDic } from "common/data/linksDic";

const ScrollItems = ({
  tours = [],
  lang = "He",
  title = "",
  slogan = "",
  carouselSelector = "glide",
  source = "Dguide",
}) => {
  const textLang = {
    yourGuide: { En: "Your Guide", He: "בהדרכת" },
    buttonText: { En: "Start Touring", He: "התחל לטייל" },
  };

  const glideOptions = {
    type: "carousel",
    gap: 0,
    autoplay: 5000,
    perView: 3.2,
    animationDuration: 700,
    breakpoints: {
      991: {
        perView: 1.2,
      },
    },
    slidesToShow: tours.length,
    slidesToScroll: tours.length,
    scrollLock: true,
    draggable: true,
  };

  //tours.map((item) => console.log("TOUR", item));
  return (
    <SectionWrapper id={`scroll_items`}>
      <Container>
        <SectionHeader>
          <Fade up>
            <Heading as="h5" content={slogan} />
            <Heading content={title} />
          </Fade>
        </SectionHeader>
        <CarouselWrapper>
          <Fade up delay={100}>
            <GlideCarousel
              options={glideOptions}
              carouselSelector={carouselSelector}
              nextButton={
                <Button
                  icon={<i className="flaticon-next" />}
                  aria-label="Next"
                  variant="fab"
                  style={{ backgroundColor: "#e14a1b" }}
                />
              }
              prevButton={
                <Button
                  icon={<i className="flaticon-left-arrow" />}
                  aria-label="Prev"
                  variant="fab"
                  style={{ backgroundColor: "#e14a1b" }}
                />
              }
            >
              <Fragment>
                {tours.length > 0 &&
                  tours.map((item) => (
                    <GlideSlide key={`testimonial--key ${item.id}`}>
                      <div className="review-card">
                        <div
                          className="top-image"
                          style={{
                            background: `linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.47) 0%,
        rgba(0, 0, 0, 0) 100%
      ),
      url('${item.tour_image_obj ? item.tour_image_obj.url : null}')`,
                            width: "100%",
                            backgroundSize: "cover",
                            height: "260px",
                            borderRadius: "5px",
                            border: "1px solid #000000",
                          }}
                          /* style={{
                            "--img": `url('${
                              item.tour_image_obj
                                ? item.tour_image_obj.url
                                : null
                            }')`,
                          }} */
                        >
                          {/*  <img
                            src={
                              item.tour_image_obj
                                ? item.tour_image_obj.url
                                : null
                            }
                            className="image-tour"
                          /> */}
                          <Heading
                            className="text-centered"
                            as="h2"
                            content={item.title}
                          />
                        </div>
                        <div className="card-content">
                          <div className="card-middel">
                            {/*<Heading as="h2" content={item.title} />*/}
                            <Text
                              style={{
                                weight: "700",
                                style: "normal",
                                size: "14px",
                                lineHeight: "18.1px",
                              }}
                              content={item.main_sentense}
                            />
                            {item.rating && item.rating.votes > 0 && (
                              <div>
                                <Rating
                                  rating={
                                    item.rating
                                      ? item.rating.total / item.rating.votes
                                      : 5
                                  }
                                />
                                {(
                                  item.rating.total / item.rating.votes
                                ).toFixed(1)}{" "}
                                / {item.rating.votes}
                              </div>
                            )}
                          </div>
                          {/*<Text content={item.description} />*/}
                          <div className="card-footer">
                            <div className="image">
                              <Image
                                src={
                                  item.tour_guide.profile_image
                                  /* item.tour_guide.profile_image
                                    ? item.tour_guide.profile_image
                                    : null */
                                }
                                alt="Guide Image"
                              />
                            </div>
                            <div className="reviewer-info">
                              <div className="content">
                                <Heading as="h4" content={item.name} />
                                <Text
                                  className="your-guide"
                                  style={{
                                    fontWeight: "500",
                                    fontSize: "14px",
                                    lineHeight: "12px",
                                    color: "#858585",
                                    marginBottom: "6px",
                                  }}
                                  content={textLang["yourGuide"][lang]}
                                />
                                <Text
                                  style={{
                                    fontWeight: "500",
                                    fontSize: "14px",
                                    lineHeight: "17px",
                                    color: "#303030",
                                  }}
                                  className="guide-name"
                                  content={item.hostedBy}
                                />
                              </div>
                              <div className="price-card">
                                {`${item.price} \u20AA`}
                              </div>
                            </div>
                          </div>
                          <div className="tour-button">
                            <Link
                              href={`${linksDic[item.id]}/?source=${source}`}
                            >
                              <Button
                                className="tour-button"
                                title={textLang["buttonText"][lang]}
                              />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </GlideSlide>
                  ))}
              </Fragment>
            </GlideCarousel>
          </Fade>
        </CarouselWrapper>
      </Container>
    </SectionWrapper>
  );
};

export default ScrollItems;
